/* components/Ticker.module.css */
.tickerWrapper {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 90%; /* Adjust width as necessary */
}

.tickerWrapper p {
  display: inline-block;
  padding-left: 20%; /* Reduce padding to speed up the appearance */
  animation: ticker 20s linear infinite; /* Reduce the total animation time */
}

@keyframes ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
